import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getColorList, resetRefList } from "../../store/colorSlice";
import ModalWindow from "../UI/ModalWindow";
import ImagePreview from "./ImagePreview";

const ColorList = () => {
	const { colorList, colorPreview } = useSelector((state) => state.color);
	const [addNewColor, setAddNewColor] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getColorList());
	}, [addNewColor]);

	const addColorHandler = () => {
		setAddNewColor(true);
	};

	const closeHandler = () => {
		setAddNewColor(false);
		dispatch(getColorList());
	};

	return (
		<Box sx={{ paddingTop: "2rem" }}>
			<Box display={"flex"} flexDirection="row" gap="28px">
				<Button
					sx={{
						border: "4px solid #4357A1",
						color: "#4357A1",
						fontFamily: "Montserrat",
						fontWeight: "600",
						fontSize: "22px",
						borderRadius: "10px",
						padding: "2px 40px",
					}}
					onClick={addColorHandler}
				>
					{t("settings.color.add")}
				</Button>
			</Box>
			<Box
				display={"flex"}
				flexDirection="row"
				paddingY={"2rem"}
				flexWrap="wrap"
				gap={"32px"}
			>
				{colorList &&
					[...colorList]
						.filter(
							(color) =>
								color.name !== "default_mono" &&
								color.name !== "default_colormix"
						)
						?.sort((a, b) => a.name.localeCompare(b.name))
						?.map((item) => (
							<Box
								key={item.uuid}
								style={{
									color: "black",
									fontSize: "24px",
								}}
								display="flex"
								flexDirection={"column"}
							>
								<ImagePreview color_id={item.uuid} />
								<Link
									onClick={() => dispatch(resetRefList())}
									to={item.uuid}
									style={{
										width: "280px",
										textAlign: "center",
									}}
								>
									{item.name}
								</Link>
							</Box>
						))}
			</Box>
			{addNewColor && (
				<ModalWindow
					opened={addNewColor}
					onClose={closeHandler}
					add={true}
				/>
			)}
		</Box>
	);
};

export default ColorList;
