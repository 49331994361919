import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setClicked } from "../../store/statsSlice";

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const Item = styled.div`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	padding: 8px;
	width: 120px;
	&:hover {
		cursor: ${(props) => (props.color ? "pointer" : "default")};
		background-color: ${(props) =>
			props.color ? "rgba(68, 132, 250, 0.2)" : "none"};
	}
`;

const StatItemTable = ({ data }) => {
	const dispatch = useDispatch();

	return (
		<Wrapper>
			<Item color="true" onClick={() => dispatch(setClicked(true))}>
				{data.form}
			</Item>
			<Item style={{ width: "200px" }} color="true">
				{data.color}
			</Item>
			<Item style={{ width: "200px" }} color="true">
				{data.user}
			</Item>
			<Item>{data.ticks}</Item>
			<Item>{data.density}</Item>
			<Item>{data.density_defects}</Item>
			<Item>{data.defect_percent}</Item>
			<Item>{data.avg_height}</Item>
		</Wrapper>
	);
};

export default StatItemTable;
