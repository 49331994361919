import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import styled from "styled-components";
import {
	getClickedChartPalletId,
	getPalletImageById,
	getPalletImageCropById,
	getSchemeById,
} from "../store/palletSlice";
import { setIsHistoryOnly } from "../store/opPanelSlice";
import { DefectCircle } from "./DefectCircle";

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	z-index: 1000;
	position: absolute;
	width: 100%;
	height: 86%;
`;

const TextWrapper = styled.div`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	width: calc(87px - 2.1%);
	text-transform: lowercase;
`;

//boxplot_colord

const DefectsList = ({ processedChart, setPalletUUid, isDrySide }) => {
	let { lastTactState } = useSelector((state) => state.pallet);
	let { chart } = useSelector((state) => state.clicked);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const schemeChart = processedChart
		? processedChart.history
		: chart?.history;
	const mockArray = [...Array(30).keys()];

	const clickHandler = (id, index) => {
		if (index === 29) {
			dispatch(setIsHistoryOnly(false));
		} else {
			dispatch(setIsHistoryOnly(true));
		}
		if (processedChart) {
			setPalletUUid(id);
		} else {
			dispatch(getClickedChartPalletId(id)); //сюда диспатч по картинке и кропу
			dispatch(getPalletImageById(id));
			dispatch(getPalletImageCropById(id));
			dispatch(getSchemeById(id));
		}
	};

	return (
		<Wrapper>
			<TextWrapper>{t("panel.prevTacts.defects")}</TextWrapper>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					height: "100%",
					justifyContent: isDrySide ? "flex-start" : "flex-end",
				}}
			>
				{schemeChart?.length > 0
					? [...schemeChart]
							.reverse()
							.slice(0, 30)
							.map((item, index) => (
								<DefectCircle
									key={item.pallet_id}
									color={
										item.content !== 0
											? "gray"
											: item.conf_status === "restricted"
											? "#FF3F3F"
											: item.conf_status === "allowed"
											? "#D9DD12"
											: "rgb(12, 156, 35)"
									}
									pallet_id={item.pallet_id}
									onClick={
										isDrySide
											? () => {}
											: () =>
													clickHandler(
														item.pallet_id,
														index
													)
									}
									sizing={schemeChart?.length === 30}
								/>
							))
					: mockArray.map((item, index) => (
							<DefectCircle key={item} color={"gray"} />
					  ))}
			</Box>
		</Wrapper>
	);
};

export default DefectsList;
