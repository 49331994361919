import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getRfidUpdates = createAsyncThunk(
	"rfid/updates",
	async (id, { rejectWithValue, extra }) => {
		const { api } = extra;
		try {
			const uuids = id?.split("|");
			const fetchMultiple = async (request) => {
				return (
					await Promise.allSettled(uuids.map((uuid) => request(uuid)))
				).map((response) => response.value);
			};
			if (id) {
				const [rfidImages, rfidMasks, rfidData] = (
					await Promise.allSettled([
						fetchMultiple((uuid) =>
							api(`/api/pallets/${uuid}/image`, {
								method: "GET",
								headers: {
									"Content-Type": "image/webp",
								},
							})
						),
						fetchMultiple((uuid) =>
							api(`/api/rfid/ts/next?pallet_id=${uuid}`, {
								method: "GET",
								headers: {
									"Content-Type": "application/json",
								},
							})
						),
						api(`/api/rfid/ts?limit=10`, {
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						}),
					])
				).map((response) => response.value);

				if (![rfidMasks, rfidImages].flat(1).some(Boolean)) throw Error;

				return { rfidImages, rfidMasks, rfidData };
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getRfidData = createAsyncThunk(
	"rfid/getRfidData",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/rfid/ts?limit=10`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const rfidSlice = createSlice({
	name: "rfid",
	initialState: {
		uuid: null,
		rfidStatus: null,
		status: null,
		rfidImages: [],
		scheme: null,
		rfidMasks: [],
		error: null,
		switchState: null,
		isMultiple: false,
		rfidData: null,
	},
	reducers: {
		switchLight(state, action) {
			state.switchState = action.payload;
		},
		setDrySideUuid(state, action) {
			state.uuid = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getRfidUpdates.pending, (state) => {
				state.rfidStatus = "loading";
				state.error = null;
			})
			.addCase(getRfidUpdates.fulfilled, (state, action) => {
				state.rfidStatus = "resolved";
				state.rfidImages = action.payload.rfidImages;
				state.rfidMasks = action.payload.rfidMasks.map(
					(rfidMask) => rfidMask.scheme
				);
				state.scheme = action.payload.rfidMasks[0];
				if (action.payload.rfidData)
					state.rfidData = action.payload.rfidData;
			})
			.addCase(getRfidUpdates.rejected, (state, action) => {
				state.rfidStatus = "rejected";
				state.error = action.payload;
			})

			.addCase(getRfidData.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(getRfidData.fulfilled, (state, action) => {
				state.status = "resolved";
				state.rfidData = action.payload;
				state.uuid = action.payload[0]?.last_paired_with;
			})
			.addCase(getRfidData.rejected, setError);
	},
});

export const { switchLight, setDrySideUuid } = rfidSlice.actions;

export default rfidSlice.reducer;
