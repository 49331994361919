import { createGlobalStyle } from "styled-components";
import MontserratWoff from "./fonts/Montserrat-Medium.woff";
import MontserratWoff2 from "./fonts/Montserrat-Medium.woff2";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'Montserrat';
  src: url(${MontserratWoff2}) format('woff2'),
       url(${MontserratWoff}) format('woff');
}
`;

export default FontStyles;
