import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { PalletView } from "../components/PalletView";
import DataList from "../components/DataList";
import InfoList from "../components/InfoList";
import Support from "./Support";
import {
	getLatestChart,
	getLatestPalletInfo,
	getClickedPallet,
	getSectorInfo,
	setDecisionBySector,
	updateAfterDecision,
} from "../store/opPanelSlice";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";

const PanelWrapper = styled.div`
	display: flex;
	flex-direction: ${(props) => (props.isSmall ? "column" : "row")};
	justify-content: space-between;
	padding: 1rem 4rem;
	gap: 1rem;
`;

const DivWrapper = styled.div`
	display: flex;
	width: ${(props) => (props.isSmall ? "100%" : "52vw")};
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
`;

const RotatableWrapper = styled.div`
	transform: rotate(${(props) => props.angle}deg) scale(${(props) =>
	props.angle === 90 || props.angle === 270 ? 0.8 : 1});
`;

const Panel = ({ blur, setBlur }) => {
	const {
		uuid,
		isHistoryOnly,
		image,
		chart,
		historyStatus,
		palletStatus,
		sectorStatus,
		decisionBySector,
		imageCrop,
		pallet,
		cloudStatus,
		scheme,
		batch,
	} = useSelector((state) => state.opPanel);
	const [imageCropCoords, setImageCropCoords] = useState(null);
	const [rotationAngle, setRotationAngle] = useState(
		Number(localStorage.getItem("rotationAngle"))
	);
	const isSmall = useMediaQuery("(max-width: 1200px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const dispatch = useDispatch();

	const { t } = useTranslation();

	useEffect(() => {
		if (isHistoryOnly) {
			dispatch(getLatestChart({ id: uuid }));
		} else {
			dispatch(getLatestPalletInfo({ id: uuid }));
		}
	}, [uuid]);

	const handleMaskClick = (maskCrop, sector_id) => {
		setImageCropCoords(maskCrop);
		dispatch(
			getSectorInfo({
				palletId: pallet.uuid,
				crop: maskCrop,
				sectorId: sector_id,
			})
		);
	};

	const handleChartClick = useCallback(
		(id) => {
			dispatch(getClickedPallet({ id: id }));
		},
		[dispatch]
	);

	const handleRotation = () => {
		setRotationAngle((prev) => {
			const result = (prev + 90) % 360;
			localStorage.setItem("rotationAngle", result);
			return result;
		});
	};

	const positiveDecisionHandler = (palletId) => (sectorId) => (defected) => {
		dispatch(
			setDecisionBySector({
				palletId,
				sectorId,
				decisionDetails: {
					decision: true,
					defect_classes: defected,
				},
			})
		).then(() => {
			dispatch(updateAfterDecision({ palletId }));
		});
	};

	const negativeDecisionHandler = (palletId) => (sectorId) => () => {
		dispatch(
			setDecisionBySector({
				palletId,
				sectorId,
				decisionDetails: {
					decision: false,
				},
			})
		).then(() => {
			dispatch(updateAfterDecision({ palletId }));
		});
	};

	const loadHandler = () => {
		setBlur(false);
	};

	const infoList = useMemo(
		() => (
			<InfoList
				chart={chart}
				batch={batch}
				pallet={pallet}
				cloudStatus={cloudStatus}
				handleRotation={handleRotation}
				angle={rotationAngle}
				handleChartClick={handleChartClick}
				loaded={historyStatus === "fulfilled"}
			/>
		),
		[
			chart,
			batch,
			handleChartClick,
			historyStatus,
			pallet,
			cloudStatus,
			rotationAngle,
		]
	);

	return (
		<PanelWrapper isSmall={isSmall || isPortrait}>
			<DivWrapper isSmall={isSmall || isPortrait}>
				<DataList
					scheme={scheme}
					isLoaded={palletStatus === "fulfilled"}
				/>
				<RotatableWrapper angle={rotationAngle}>
					<PalletView
						blur={blur}
						scheme={scheme}
						imageCrop={imageCropCoords}
						imagesCrop={imageCrop}
						angle={rotationAngle}
						isSectorLoaded={sectorStatus === "fulfilled"}
						decisionBySector={decisionBySector}
						positiveDecisionHandler={positiveDecisionHandler(
							pallet?.uuid
						)}
						negativeDecisionHandler={negativeDecisionHandler(
							pallet?.uuid
						)}
						loadHandler={loadHandler}
						handleMaskClick={handleMaskClick}
						images={[image]}
					/>
				</RotatableWrapper>
			</DivWrapper>
			{infoList}
			<Support />
		</PanelWrapper>
	);
};

export default Panel;
