import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.tr`
	
`;

const Shift = styled.div`
	padding-top: 16px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 17px;
	display: flex;
	flex-direction: row;
`;

const Divider = styled.hr`
	margin-top: 0px;
	width: 100%;
	border: 0;
	border-top: 3px solid #606060;
	margin-left: 0;
`;

const ReportRow = ({ count, time, rfid_overall, rfid_paired }) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<td colSpan={999}>
				<Shift>
					{t("report.shift")} {count} {time}
					{rfid_overall && rfid_paired ? (
						<>
							({t("report.table.rfidAll")} - {rfid_overall},{" "}
							{t("report.table.rfid")} - {rfid_paired})
						</>
					) : null}
				</Shift>
				<Divider />
			</td>
		</Wrapper>
	);
};

export default ReportRow;
