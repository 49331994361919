import { useState } from "react";
import styled from "styled-components";
import AlertDialog from "./UI/AlertDialog";

const PalletWrapper = styled.div`
	aspect-ratio: 14/11;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	border-radius: 12px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

const SvgWrapper = styled.svg.attrs({
	xmlns: "http://www.w3.org/2000/svg",
	viewBox: "0 0 1400 1100",
})`
	width: 100%;
	aspect-ratio: 14/11;
	border-radius: 12px;
	transform: rotate(${(props) => props.angle}deg);
`;

const ImageWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
`;

export const PalletView = ({
	blur,
	scheme,
	imageCrop,
	imagesCrop,
	positiveDecisionHandler,
	negativeDecisionHandler,
	decisionBySector,
	selectedDefects,
	angle = 0,
	loadHandler,
	isSectorLoaded,
	handleMaskClick,
	images,
}) => {
	const [isOpened, setIsOpened] = useState(false);
	const [sectorId, setSectorId] = useState(null);

	return scheme ? (
		<PalletWrapper>
			<ImageWrapper>
				<SvgWrapper>
					{images && (
						<image
							href={
								Array.isArray(images)
									? images?.length > 1
										? images[images.length - 1]
										: images[0]
									: images
							}
							style={{
								filter: blur ? "blur(20px)" : "none",
								transition: blur
									? "none"
									: "filter 0.5s ease-out",
							}}
							onLoad={loadHandler}
						/>
					)}
					{!blur ? (
						scheme?.scheme.sectors.map((mask) =>
							mask.grid && mask.grid?.length > 1
								? mask.grid.map((grid) => (
										<g key={grid.points}>
											<text
												filter="url(#rounded-corners)"
												fill={
													grid.heights_repeat_count
														? "red"
														: "#fff"
												}
												fontSize={18}
												textAnchor={"middle"}
												alignmentBaseline={"middle"}
												stroke="black"
												transform={`rotate(-${angle})`}
												strokeWidth={0.2}
												x={Number(
													grid.center_text_x_y
														.split(" ")[0]
														?.slice(2)
												)}
												y={Number(
													grid.center_text_x_y
														.split(" ")[1]
														?.slice(2)
												)}
												transform-origin={grid?.center_text_x_y?.replace(
													/x=|y=/g,
													""
												)}
											>
												{grid.height.toFixed(1)}
											</text>
											<polygon
												fill="#00000000"
												stroke={"#FFFFFF"}
												strokeWidth={"0.5px"}
												points={grid.points}
											/>
										</g>
								  ))
								: null
						)
					) : (
						<></>
					)}
					{!blur && scheme.content === 0 ? (
						scheme?.scheme.defect_stones.length > 0 ? (
							scheme?.scheme.defect_stones.map((stone) =>
								!(selectedDefects?.length && selectedDefects) ||
								selectedDefects
									.map((defect) => {
										const currentSector =
											scheme?.scheme.sectors.find(
												(sector) =>
													sector.points ===
													stone.points
											);
										return (
											currentSector?.neuro_classes.includes(
												defect
											) ||
											(defect === 1 &&
												currentSector.point_cloud_defect)
										);
									})
									.some(Boolean) ? (
									<g key={stone.id}>
										<polygon
											id={`${stone.id}`}
											fill="#00000000"
											stroke={"#FFF85C"}
											strokeWidth={"5px"}
											points={stone.points}
										/>
									</g>
								) : null
							)
						) : (
							<></>
						)
					) : (
						<></>
					)}
					{!blur && scheme.content === 0 ? (
						scheme?.scheme.repeated_defect_stones.length > 0 ? (
							scheme?.scheme.repeated_defect_stones.map(
								(stone) => (
									<g key={stone.id}>
										<polygon
											id={`${stone.id}`}
											fill="#00000000"
											stroke={"#873FA0"}
											strokeWidth={"5px"}
											points={stone.points}
										/>
									</g>
								)
							)
						) : (
							<></>
						)
					) : (
						<></>
					)}
					{!blur && scheme.content === 0 ? (
						scheme?.scheme.repeated_height_stones.length > 0 ? (
							scheme?.scheme.repeated_height_stones.map(
								(stone) => (
									<g key={stone.id}>
										<polygon
											id={`${stone.id}`}
											fill="#00000000"
											stroke={"#873FA0"}
											strokeWidth={"5px"}
											points={stone.points}
										/>
									</g>
								)
							)
						) : (
							<></>
						)
					) : (
						<></>
					)}
					{!blur ? (
						scheme?.scheme.sectors.map((mask) => (
							<g
								key={mask.id}
								onClick={() => {
									setSectorId(mask.id);
									handleMaskClick(mask.crop, mask.id);
									setIsOpened(true);
								}}
							>
								<filter
									id="rounded-corners"
									x="-5%"
									width="110%"
									y="0%"
									height="100%"
								>
									<feFlood floodColor="#353535" />
									<feGaussianBlur stdDeviation="2" />
									<feComponentTransfer>
										<feFuncA
											type="table"
											tableValues="0 0 0 1"
										/>
									</feComponentTransfer>

									<feComponentTransfer>
										<feFuncA
											type="table"
											tableValues="0 1 1 1 1 1 1 1"
										/>
									</feComponentTransfer>
									<feComposite
										operator="over"
										in="SourceGraphic"
									/>
								</filter>
								{!(mask.grid && mask.grid?.length > 1) ? (
									<text
										filter="url(#rounded-corners)"
										fill={
											mask.heights_repeat_count
												? "#ff0000"
												: "#fff"
										}
										fontSize={24}
										textAnchor={"middle"}
										alignmentBaseline={"middle"}
										stroke="black"
										transform={`rotate(-${angle})`}
										strokeWidth={0.2}
										x={Number(
											mask.center_text_x_y
												.split(" ")[0]
												?.slice(2)
										)}
										y={Number(
											mask.center_text_x_y
												.split(" ")[1]
												?.slice(2)
										)}
										transform-origin={mask?.center_text_x_y?.replace(
											/x=|y=/g,
											""
										)}
									>
										{mask.value}
									</text>
								) : null}
								<polygon
									id={`${mask.id}`}
									fill="#00000000"
									stroke={
										mask.user_decision === false
											? "green"
											: mask.user_decision === true
											? "red"
											: mask.user_decision === null
											? "0"
											: "0"
									}
									strokeWidth={
										mask.user_decision === false ||
										mask.user_decision === true
											? "10px"
											: "0px"
									}
									strokeDasharray={
										mask.user_decision === false ||
										mask.user_decision === true
											? "10"
											: ""
									}
									points={mask.points}
								/>
							</g>
						))
					) : (
						<></>
					)}
					<AlertDialog
						opened={isOpened}
						isSectorLoaded={isSectorLoaded}
						imageCrop={imageCrop}
						onClose={() => setIsOpened(false)}
						sectorId={sectorId}
						positiveDecisionHandler={positiveDecisionHandler(
							sectorId
						)}
						negativeDecisionHandler={negativeDecisionHandler(
							sectorId
						)}
						decision={decisionBySector}
						sectorImgCrop={imagesCrop}
					/>
				</SvgWrapper>
			</ImageWrapper>
		</PalletWrapper>
	) : (
		<></>
	);
};
