import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeSystem } from "../../store/settingsSlice";
import { fetchSystem } from "../../helper/fetchSystem";

const MeasureUnits = () => {
	const { system } = useSelector((state) => state.settings);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const dencityMeasureUnits = [
		{ value: "kg_m3", name: t("measureUnits.kg/m") },
		{ value: "kg_dm3", name: t("measureUnits.kg/dm") },
	];

	const densityChangeHandler = async (event) => {
		const currentSystem = await fetchSystem();
		const newState = {
			...currentSystem,
			density_measure_unit: event.target.value,
		};
		dispatch(changeSystem({ settingsState: newState }));
	};

	return (
		<Box
			sx={{
				display: "flex",
				maxWidth: "fit-content",
				flexDirection: "column",
				gap: "1rem",
			}}
		>
			<Typography
				fontFamily="Montserrat"
				fontSize={"24px"}
				fontWeight="bold"
			>
				{t("measureUnits.title")}
			</Typography>
			{system && (
				<Box display={"flex"} alignItems={"center"} gap={"15px"}>
					<Typography>{t("measureUnits.density")}:</Typography>
					<Select
						onChange={densityChangeHandler}
						value={system?.density_measure_unit}
					>
						{dencityMeasureUnits.map((unit) => (
							<MenuItem key={unit.value} value={unit.value}>
								{unit.name}
							</MenuItem>
						))}
					</Select>
				</Box>
			)}
		</Box>
	);
};

export default MeasureUnits;
