import { Box, Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LayoutItem } from "../layout/LayoutItem";

import DefaultSnackbar from "../profile/Snackbar";
import { getLayoutPreviewByLayoutId } from "../layout/LayoutFetch";
import { getProducts } from "../../store/productSlice";

const LayoutItems = styled.div`
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	padding: 2rem 0;
`;

const LayoutList = () => {
	const { products } = useSelector((state) => state.product);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showSnack, setShowSnack] = useState({
		show: false,
		success: false,
		msg: "msg",
	});
	const [layoutPreviews, setLayoutPreviews] = useState([]);
	const [areLayoutsLoading, setAreLayoutsLoading] = useState(true);

	useEffect(() => {
		dispatch(getProducts());
	}, []);

	useEffect(() => {
		if (products) {
			Promise.allSettled(
				products.map(async (product) => {
					return {
						preview: await getLayoutPreviewByLayoutId(product.uuid),
						uuid: product.uuid,
					};
				})
			).then((result) => {
				setLayoutPreviews(result.map((res) => res.value));
				setAreLayoutsLoading(false);
			});
		}
	}, [products]);

	const addLayoutHandler = () => {
		navigate("/settings/form/add");
	};

	const layoutOptions = useMemo(() => {
		return products?.map((product) => (
			<LayoutItem
				key={product.uuid}
				isLoading={areLayoutsLoading}
				layout={product}
				preview={
					layoutPreviews.find(
						(preview) => preview?.uuid === product.uuid
					)?.preview
				}
			/>
		));
	}, [products, layoutPreviews]);

	return (
		<Box sx={{ paddingTop: "32px", paddingBottom: "40px" }}>
			<Button
				sx={{
					border: "4px solid #4357A1",
					color: "#4357A1",
					fontFamily: "Montserrat",
					fontWeight: "600",
					fontSize: "22px",
					borderRadius: "10px",
					padding: "2px 40px",
				}}
				onClick={addLayoutHandler}
			>
				{t("qpbc.entities.layout.create")}
			</Button>
			<LayoutItems>{layoutOptions}</LayoutItems>
			<DefaultSnackbar
				open={showSnack.show}
				setOpen={setShowSnack}
				error={showSnack.success}
				msg={showSnack.msg}
			/>
		</Box>
	);
};

export default LayoutList;
