import { configureStore } from "@reduxjs/toolkit";
import palletReducer from "./palletSlice";
import reportReducer from "./reportSlice";
import settingsReducer from "./settingsSlice";
import statsReducer from "./statsSlice";
import supportReducer from "./supportSlice";
import userReducer from "./userSlice";
import clickedReducer from "./clickedSlice";
import authReducer, { authLogout } from "./authSlice";
import colorReducer from "./colorSlice";
import productReducer from "./productSlice";
import panelReducer from "./opPanelSlice";
import rfidReducer from "./rfidSlice";
import newReportReducer from "./newReportSlice";
import defectInspectionReducer from "./defectsInspectionSlice";
import formReducer from "./formSlice";
import { palletApi } from "./palletApi";
import requestWrapper from "../helper/requestWrapper";

export const store = configureStore({
	reducer: {
		pallet: palletReducer,
		report: reportReducer,
		newReport: newReportReducer,
		settings: settingsReducer,
		stats: statsReducer,
		defectInspection: defectInspectionReducer,
		support: supportReducer,
		user: userReducer,
		clicked: clickedReducer,
		product: productReducer,
		auth: authReducer,
		opPanel: panelReducer,
		color: colorReducer,
		rfid: rfidReducer,
		form: formReducer,
		[palletApi.reducerPath]: palletApi.reducer,
	},
	middleware: (getDefaults) =>
		getDefaults({
			thunk: {
				extraArgument: {
					api: requestWrapper(() => {
						store.dispatch(authLogout());
					}),
				},
			},
		}),
});
