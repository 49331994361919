import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import DataItem from "./DataItem";
import { Box, Skeleton } from "@mui/material";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import ColorDistr from "./ColorDistr";

const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 12px;
	gap: 8px;
	padding: 8px;
`;

const TitleWrapper = styled.div`
	display: flex;
	justify-content: center;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: ${(props) => (props.isSmall ? "2.3vw" : "1.3vw")} ;
	line-height: 27px;
`;

const ItemsWrapper = styled.div`
	display: flex;
	flex-direction: ${(props) => (props.isVertical ? "column" : "row")};
	justify-content: ${(props) => (props.isVertical ? "space-between" : "initial")};
	height: ${(props) => (props.isVertical ? "100%" : "initial")};
	gap: 8px;
`;

const DataList = ({ scheme, isLoaded, isVertical }) => {
	const { equipment, system } = useSelector((state) => state.settings);
	const { t } = useTranslation();
	const isSmall = useMediaQuery("(max-width: 1200px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");

	if (!equipment) return <></>;
	if (!scheme)
		return (
			<>
				<Skeleton width="100%" height="140px" />
			</>
		);

	const schemeChart = scheme?.chart.history;

	const midHeight = (
		schemeChart[0]?.heights.reduce((prev, cur) => prev + cur, 0) /
		schemeChart[0]?.heights.length
	).toFixed(1);

	const titleColor = () => {
		if (
			[
				"heights_status",
				"defects_status",
				"repeat_status",
				"avg_overlimit_status",
				"density_overlimit_status",
			].some((status) => scheme[status] === "restricted")
		) {
			return "#FF0000";
		}
		if (
			[
				"heights_status",
				"defects_status",
				"repeat_status",
				"avg_overlimit_status",
				"density_overlimit_status",
			].some((status) => scheme[status] === "allowed")
		) {
			return "#FFF85C";
		}

		return "#0C9C23";
	};

	if (scheme?.content !== 0)
		return (
			<ListWrapper
				style={{
					border: `4px solid transparent`,
					borderColor: "#D9D9D9",
				}}
			>
				{!isVertical && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Box sx={{ fontSize: isSmall ? "1.3vw" : "0.8vw" }}>
							{t("panel.titles.time")}:{" "}
							{scheme?.chart.history[0].pallet_id}
						</Box>
						<TitleWrapper
							isSmall={isSmall || isPortrait}
							style={{ color: "#868686" }}
						>
							{scheme?.content === 1
								? t("panel.allLabelEmpty")
								: t("panel.allLabelReset")}
						</TitleWrapper>
						<Box sx={{ width: "13rem" }}></Box>
					</Box>
				)}
				<ItemsWrapper isVertical={isVertical}>
					<DataItem
						title={t("panel.titles.tact")}
						data={scheme?.tick_time && scheme?.tick_time.toFixed(1)}
						color={"#D9D9D9"}
						loaded={isLoaded}
					/>
					<DataItem
						title={t("panel.titles.heights")}
						color={"#D9D9D9"}
						data={"N/A"}
						loaded={isLoaded}
					/>
					<DataItem
						title={t("panel.titles.defects")}
						color={"#D9D9D9"}
						data={"N/A"}
						loaded={isLoaded}
					/>
					<DataItem
						title={t("panel.titles.repeat")}
						color={"#D9D9D9"}
						data={"N/A"}
						loaded={isLoaded}
					/>
					{equipment.weigher ? (
						<DataItem
							title={t("panel.titles.density")}
							color={"#D9D9D9"}
							data={"N/A"}
							loaded={isLoaded}
						/>
					) : null}
					<DataItem
						title={t("panel.titles.avg")}
						color={"#D9D9D9"}
						data={"N/A"}
						loaded={isLoaded}
					/>
				</ItemsWrapper>
			</ListWrapper>
		);

	return (
		<ListWrapper
			style={{
				border: `4px solid transparent`,
				borderColor: titleColor(),
			}}
		>
			{!isVertical && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box sx={{ fontSize: isSmall ? "1.3vw" : "0.8vw" }}>
						{t("panel.titles.time")}:{" "}
						{scheme?.chart.history[0].pallet_id}
					</Box>
					{titleColor() === "#0C9C23" && (
						<TitleWrapper isSmall={isSmall || isPortrait}>
							{t("panel.allLabelOk")}
						</TitleWrapper>
					)}
					{titleColor() === "#FFF85C" && (
						<TitleWrapper isSmall={isSmall || isPortrait}>
							{t("panel.allLabelSomeProb")}
						</TitleWrapper>
					)}
					{titleColor() === "#FF0000" && (
						<TitleWrapper
							isSmall={isSmall || isPortrait}
							style={{ color: "#FF0000" }}
						>
							{t("panel.allLabelTroubles")}
						</TitleWrapper>
					)}
					<ColorDistr distr={scheme?.color_distribution} />
				</Box>
			)}

			<ItemsWrapper isVertical={isVertical}>
				<DataItem
					title={t("panel.titles.tact")}
					data={scheme?.tick_time && scheme?.tick_time.toFixed(1)}
					color={"#000000"}
					loaded={isLoaded}
				/>
				<DataItem
					title={t("panel.titles.heights")}
					data={
						scheme.heights_error_count
							? `${scheme.heights_error_count}`
							: scheme.heights_error_count === 0
							? `${scheme.heights_error_count}`
							: 0
					}
					loaded={isLoaded}
					status={scheme?.heights_status}
				/>
				<DataItem
					title={t("panel.titles.defects")}
					status={scheme?.defects_status}
					data={
						scheme.defects_error_count
							? `${scheme.defects_error_count}`
							: scheme.defects_error_count === 0
							? `${scheme.defects_error_count}`
							: 0
					}
					loaded={isLoaded}
				/>
				<DataItem
					title={t("panel.titles.repeat")}
					status={scheme?.repeat_status}
					data={
						scheme.repeat_error_count
							? `${scheme.repeat_error_count}`
							: scheme.repeat_error_count === 0
							? `${scheme.repeat_error_count}`
							: 0
					}
					loaded={isLoaded}
				/>
				{equipment.weigher ? (
					<DataItem
						title={[
							t("panel.titles.density"),
							t("panel.titles.weight"),
							t("panel.titles.palletWeight"),
						]}
						data={[
							scheme.density !== "N/A" &&
							system?.density_measure_unit === "kg_dm3"
								? (scheme.density / 1000).toFixed(2)
								: scheme.density,
							scheme.weight,
							scheme.pallet_weight,
						]}
						status={scheme?.density_overlimit_status}
						loaded={isLoaded}
					/>
				) : null}
				<DataItem
					title={t("panel.titles.avg")}
					status={scheme?.avg_overlimit_status}
					data={midHeight ? midHeight : "N/A"}
					loaded={isLoaded}
				/>
			</ItemsWrapper>
		</ListWrapper>
	);
};

export default DataList;
