import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPalletDecision } from "./defectsInspectionSlice";

const defectStatusToColor = new Map([
	["ok", "green"],
	["allowed", "#ffd900"],
	["restricted", "red"],
]);

const getColor = (pallet) => {
	if (pallet.content) return "grey";
	return defectStatusToColor.get(pallet.quality_status.defect_status);
};

export const getXLSXReport = createAsyncThunk(
	"/reports/xlsx",
	async ({ from, to }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const report = await api(
				`/api/batches/report/xlsx?dt_begin=${encodeURIComponent(
					from
				)}&dt_end=${encodeURIComponent(to)}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/octet-stream",
					},
				}
			);
			const download = document.createElement("a");
			download.style.display = "none";
			download.href = report.url;
			download.download = report.fileName;
			document.body.appendChild(download);
			download.click();
			download.remove();
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const requestTempBatches = createAsyncThunk(
	"/reports/temp",
	async ({ batch }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const previousBatch = await api(
				`/api/batches/interval?dt_end=${encodeURIComponent(
					batch.bdt
				)}&limit=1&desc=true`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			const nextBatch = await api(
				`/api/batches/interval?dt_begin=${encodeURIComponent(
					batch.edt
				)}&limit=1&desc=false`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			return [...previousBatch, batch, ...nextBatch];
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const requestBatches = createAsyncThunk(
	"/reports",
	async ({ from, to, filters }, { extra, rejectWithValue, dispatch }) => {
		const { api } = extra;
		try {
			dispatch(selectBatch(null));
			const batches = await api(
				`/api/batches/date?dt_begin=${encodeURIComponent(
					from
				)}&dt_end=${encodeURIComponent(
					to
				)}&limit=100&desc=false${Object.keys(filters)
					.filter((filterField) =>
						Array.isArray(filters[filterField])
							? filters[filterField].length
							: filters[filterField]
					)
					.map(
						(filterField) =>
							`&${filterField}=${
								Array.isArray(filters[filterField])
									? filters[filterField].join(",")
									: filters[filterField]
							}`
					)
					.join("")}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			const processedBatches = batches.map((batch, index) => {
				const begin = +new Date(batch.bdt);
				return {
					...batch,
					begin: begin,
					uuid: batch.bdt,
					dt: batch.bdt,
					end: +new Date(batch.edt),
					marginMS:
						index === 0
							? 0
							: begin - new Date(batches[index - 1].edt),
					displayColor: index % 2 === 0 ? "grey" : "lightgrey",
					operator: batch?.user?.name,
				};
			});
			dispatch(setRequestedDates({ from, to }));
			return processedBatches;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getAllPalletsFromBatch = createAsyncThunk(
	"/batchPallets",
	async ({ batch, filters }, { extra, rejectWithValue, dispatch }) => {
		const { api } = extra;
		try {
			dispatch(selectBatch(batch));
			let pallets, filteredPallets;
			if (
				filters &&
				Object.keys(filters).some((entry) => filters[entry])
			) {
				[pallets, filteredPallets] = await Promise.all([
					api(
						`/api/pallets?join_status=true&join_settings=true&limit=100000&dt_begin=${encodeURIComponent(
							batch.bdt
						)}&dt_end=${encodeURIComponent(batch.edt)}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						}
					),
					api(
						`/api/pallets?join_status=true&limit=100000&dt_begin=${encodeURIComponent(
							batch.bdt
						)}&dt_end=${encodeURIComponent(
							batch.edt
						)}&${Object.keys(filters)
							.filter(
								(field) =>
									!(
										field === "user_decision" ||
										field === "user_decision_alt"
									)
							)
							.filter((entry) => filters[entry])
							.map((entry) => entry + "=" + filters[entry])
							.join("&")}${
							filters.user_decision && filters.user_decision_alt
								? "&user_decision=2"
								: filters.user_decision_alt
								? "&user_decision=1"
								: filters.user_decision
								? "&user_decision=0"
								: ""
						}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						}
					),
				]);
				filteredPallets = filteredPallets.map((pallet) => pallet.uuid);
				pallets = pallets.map((pallet) => ({
					...pallet,
					isHidden: !filteredPallets.includes(pallet.uuid),
				}));
			} else {
				pallets = await api(
					`/api/pallets?join_status=true&join_settings=true&limit=100000&dt_begin=${encodeURIComponent(
						batch.bdt
					)}&dt_end=${encodeURIComponent(batch.edt)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
			}
			const processedPallets = pallets.map((pallet, index) => {
				return {
					...pallet,
					displayColor: getColor(pallet),
					uuid: pallet.uuid,
					avg: pallet.avg ? pallet.avg : null,
					begin: +new Date(batch.bdt),
					end: +new Date(batch.edt),
					isHidden: pallet.isHidden,
					marginMS:
						index === 0
							? +new Date(pallet.dt) - new Date(batch.bdt)
							: +new Date(pallet.dt) -
							  new Date(pallets[index - 1].dt),
					imageRef: pallet.image_ref,
				};
			});
			return {
				processedPallets,
				filteredPallets:
					filteredPallets ??
					processedPallets.map((pallet) => pallet.uuid),
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getFilteredPallets = createAsyncThunk(
	"/filteredPallets",
	async ({ batch, filters }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return (
				await api(
					`/api/pallets?join_status=true&limit=100000&dt_begin=${encodeURIComponent(
						batch.bdt
					)}&dt_end=${encodeURIComponent(batch.edt)}&${Object.keys(
						filters
					)
						.filter(
							(field) =>
								!(
									field === "user_decision" ||
									field === "user_decision_alt"
								)
						)
						.filter((entry) => filters[entry])
						.map((entry) => entry + "=" + filters[entry])
						.join("&")}${
						filters.user_decision && filters.user_decision_alt
							? "&user_decision=2"
							: filters.user_decision_alt
							? "&user_decision=1"
							: filters.user_decision
							? "&user_decision=0"
							: ""
					}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
			).map((pallet) => pallet.uuid);
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getImageForPallet = createAsyncThunk(
	"/batchPallets/image",
	async ({ palletId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return {
				uuid: palletId,
				image: await api(
					`/api/pallets/${encodeURIComponent(
						palletId
					)}/image?zipped=true`,
					{
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}
				),
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getPalletInfo = createAsyncThunk(
	"/report/palletById",
	async ({ id }, { extra, rejectWithValue, dispatch }) => {
		const { api } = extra;
		try {
			const [scheme, image] = (
				await Promise.allSettled([
					api(`/api/pallets/${id}/scheme`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}),
					api(`/api/pallets/${id}/image`, {
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}),
					dispatch(
						getPalletDecision({
							id,
						})
					),
				])
			).map((result) => result.value);
			return { scheme, image };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSectorInfo = createAsyncThunk(
	"/sector/info",
	async ({ palletId, crop, sectorId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const [imageCrop, decision] = await Promise.all([
				api(
					`/api/pallets/${palletId}/image?crop=%5B${crop[0]}%2C%20${crop[1]}%2C%20${crop[2]}%2C%20${crop[3]}%5D`,
					{
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}
				),
				api(`/api/pallets/${palletId}/${sectorId}/decision`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}),
			]);

			return { imageCrop, decision };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateAfterDecision = createAsyncThunk(
	"/sector/updateAfterDecision",
	async ({ palletId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return await api(`/api/pallets/${palletId}/scheme`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const pointCloudDownloadReport = createAsyncThunk(
	"/report/pointCloud",
	async ({ palletId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const cloud = await api(`/api/pallets/${palletId}/cloud/download`, {
				method: "GET",
				headers: {
					"Content-Type": "application/octet-stream",
				},
			});
			const download = document.createElement("a");
			download.style.display = "none";
			download.href = cloud.url;
			download.download = cloud.fileName;
			document.body.appendChild(download);
			download.click();
			download.remove();
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const submitBatchEdits = createAsyncThunk(
	"/batch/properties",
	async (
		{
			batchId,
			product_id,
			color_id,
			user_id,
			begin_dt,
			end_dt,
			filters,
			requestedDates,
		},
		{ extra, rejectWithValue, dispatch }
	) => {
		const { api } = extra;
		try {
			await api(`/api/batches/${encodeURIComponent(batchId)}`, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					product_id,
					color_id,
					user_id,
					begin_dt,
					end_dt,
				}),
			});
			if (filters && requestedDates)
				dispatch(requestBatches({ ...requestedDates, filters }));
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const submitSplit = createAsyncThunk(
	"/batch/split",
	async (
		{
			batchId,
			product_id_l,
			color_id_l,
			user_id_l,
			product_id_r,
			color_id_r,
			user_id_r,
			split_dt,
			filters,
			requestedDates,
		},
		{ extra, rejectWithValue, dispatch }
	) => {
		const { api } = extra;
		try {
			await api(`/api/batches/${encodeURIComponent(batchId)}/split`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					split_dt,
					product_id_l,
					color_id_l,
					user_id_l,
					product_id_r,
					color_id_r,
					user_id_r,
				}),
			});
			dispatch(requestBatches({ ...requestedDates, filters }));
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const submitMerge = createAsyncThunk(
	"/batch/split",
	async (
		{ batch_ids, product_id, color_id, user_id, requestedDates, filters },
		{ extra, rejectWithValue, dispatch }
	) => {
		const { api } = extra;
		try {
			await api(`/api/batches/merge`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					batch_ids,
					product_id,
					color_id,
					user_id,
				}),
			});
			dispatch(requestBatches({ ...requestedDates, filters }));
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const initialState = {
	batches: [],
	batchesStatus: "fulfilled",
	selectedBatch: null,
	palletsForBatch: [],
	filteredPallets: [],
	palletsStatus: "pending",
	reportStatus: "fulfilled",
	pointCloudDownloadReport: "fulfilled",
	palletInfoStatus: "pending",
	sectorStatus: "pending",
	palletImages: [],
	requestedDates: {
		from: null,
		to: null,
	},
	scheme: null,
	image: null,
	imageCrop: null,
	decision: null,
	propertiesStatus: "pending",
	tempBatches: [],
};

const newReportSlice = createSlice({
	name: "newReport",
	initialState,
	reducers: {
		selectBatch: (state, action) => {
			state.selectedBatch = action.payload;
		},
		setRequestedDates: (state, action) => {
			state.requestedDates = action.payload;
		},
		statusReset: (state) => {
			state.propertiesStatus = "pending";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestBatches.pending, (state) => {
				state.batchesStatus = "pending";
			})
			.addCase(requestBatches.fulfilled, (state, action) => {
				state.batchesStatus = "fulfilled";
				state.batches = action.payload;
			})
			.addCase(requestBatches.rejected, (state) => {
				state.batchesStatus = "rejected";
			})
			.addCase(getAllPalletsFromBatch.pending, (state) => {
				state.palletsStatus = "pending";
			})
			.addCase(getAllPalletsFromBatch.fulfilled, (state, action) => {
				state.palletsStatus = "fulfilled";
				state.palletsForBatch = action.payload.processedPallets;
				state.filteredPallets = action.payload.filteredPallets;
				state.palletImages = action.payload.processedPallets.map(
					(pallet) => ({
						url:
							state.palletImages?.find(
								(image) => image.uuid === pallet.uuid
							)?.url ?? null,
						uuid: pallet.uuid,
						label: pallet.uuid,
						load: (dispatch) =>
							dispatch(
								getImageForPallet({ palletId: pallet.uuid })
							),
					})
				);
			})
			.addCase(getAllPalletsFromBatch.rejected, (state) => {
				state.palletsStatus = "rejected";
			})
			.addCase(getFilteredPallets.fulfilled, (state, action) => {
				state.filteredPallets = action.payload;
				state.palletsForBatch = state.palletsForBatch.map((pallet) => ({
					...pallet,
					isHidden: !action.payload.includes(pallet.uuid),
				}));
			})
			.addCase(getXLSXReport.pending, (state) => {
				state.reportStatus = "pending";
			})
			.addCase(getXLSXReport.fulfilled, (state) => {
				state.reportStatus = "fulfilled";
			})
			.addCase(getXLSXReport.rejected, (state) => {
				state.reportStatus = "rejected";
			})
			.addCase(getImageForPallet.fulfilled, (state, action) => {
				const imageIndex = state.palletImages.findIndex(
					(image) => image.label === action.payload.uuid
				);
				const temp = state.palletImages[imageIndex];
				temp.url = action.payload.image;
				state.palletImages[imageIndex] = temp;
			})
			.addCase(getSectorInfo.pending, (state) => {
				state.sectorStatus = "pending";
			})
			.addCase(getSectorInfo.fulfilled, (state, action) => {
				state.sectorStatus = "fulfilled";
				state.imageCrop = action.payload.imageCrop;
				state.decision = action.payload.decision;
			})
			.addCase(getSectorInfo.rejected, (state) => {
				state.sectorStatus = "rejected";
			})
			.addCase(getPalletInfo.pending, (state) => {
				state.palletInfoStatus = "pending";
			})
			.addCase(getPalletInfo.fulfilled, (state, action) => {
				state.palletInfoStatus = "fulfilled";
				state.scheme = action.payload.scheme;
				state.image = action.payload.image;
			})
			.addCase(getPalletInfo.rejected, (state) => {
				state.palletInfoStatus = "rejected";
			})
			.addCase(updateAfterDecision.fulfilled, (state, action) => {
				state.scheme = action.payload;
			})
			.addCase(pointCloudDownloadReport.pending, (state) => {
				state.cloudStatus = "pending";
			})
			.addCase(pointCloudDownloadReport.fulfilled, (state) => {
				state.cloudStatus = "fulfilled";
			})
			.addCase(pointCloudDownloadReport.rejected, (state) => {
				state.cloudStatus = "rejected";
			})
			.addCase(submitBatchEdits.pending, (state) => {
				state.propertiesStatus = "pending";
			})
			.addCase(submitBatchEdits.fulfilled, (state) => {
				state.propertiesStatus = "fulfilled";
			})
			.addCase(submitBatchEdits.rejected, (state) => {
				state.propertiesStatus = "rejected";
			})
			.addCase(requestTempBatches.fulfilled, (state, action) => {
				console.log(action.payload);
				state.tempBatches = action.payload;
			});
	},
});

export const { selectBatch, setRequestedDates, statusReset } =
	newReportSlice.actions;

export default newReportSlice.reducer;
