import { Box } from "@mui/material";
import React from "react";
import PanelColorTooltip from "./UI/PanelColorTooltip";

const ColorDistr = ({ distr }) => {
	if (!distr) return <></>;

	const stringDistr = distr.map((obj) => {
		return `${obj.rgb} - ${obj.percentage}%; `;
	});

	return (
		<PanelColorTooltip
			title={"".concat(...stringDistr)}
			placement="bottom-end"
		>
			<Box
				display={"flex"}
				flexDirection="row"
				sx={{ width: "30%", maxWidth: "13rem", height: "1rem" }}
			>
				{distr.map((obj) => (
					<Box
						key={obj.rgb}
						sx={{
							backgroundColor: obj.rgb,
							height: "1rem",
							width: `${obj.percentage}%`,
						}}
					></Box>
				))}
			</Box>
		</PanelColorTooltip>
	);
};

export default ColorDistr;
