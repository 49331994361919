import React from "react";
import styled, { keyframes } from "styled-components";

const loader1 = keyframes`
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
`;

const loader2 = keyframes`
  0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
`;

const loader3 = keyframes`
  0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
`;

const loader4 = keyframes`
  0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, 24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
`;

const loader5 = keyframes`
  0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(0, -24px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
`;

const Loader = styled.div`
	height: 32px;
	width: 32px;
	-webkit-animation: ${loader1} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: ${loader1} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: auto;
		margin: auto;
		width: 8px;
		height: 8px;
		background: #000000;
		border-radius: 50%;
		-webkit-animation: ${loader2} 2s cubic-bezier(0.77, 0, 0.175, 1)
			infinite;
		animation: ${loader2} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: auto;
		bottom: 0;
		right: 0;
		margin: auto;
		width: 8px;
		height: 8px;
		background: #000000;
		border-radius: 50%;
		-webkit-animation: ${loader3} 2s cubic-bezier(0.77, 0, 0.175, 1)
			infinite;
		animation: ${loader3} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	}
`;

const Span = styled.span`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	height: 32px;
	width: 32px;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: auto;
		right: 0;
		margin: auto;
		width: 8px;
		height: 8px;
		background: #000000;
		border-radius: 50%;
		-webkit-animation: ${loader4} 2s cubic-bezier(0.77, 0, 0.175, 1)
			infinite;
		animation: ${loader4} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: auto;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		width: 8px;
		height: 8px;
		background: #000000;
		border-radius: 50%;
		-webkit-animation: ${loader5} 2s cubic-bezier(0.77, 0, 0.175, 1)
			infinite;
		animation: ${loader5} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	}
`;

const CircleLoader = () => {
	return (
		<Loader>
			<Span></Span>
		</Loader>
	);
};

export default CircleLoader;
