import { Box, Button, Divider } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
	padding: 0 60px;
`;

const LinkButton = ({ children, link, sx }) => {
	const navigate = useNavigate();
	const clickHandle = (link) => {
		navigate(link);
	};

	return (
		<Button
			sx={{
				...sx,
				borderRadius: "10px",
				padding: "0px 25px",
				fontFamily: "Montserrat",
				fontWeight: "400",
				fontSize: "20px",
			}}
			onClick={() => clickHandle(link)}
		>
			{children}
		</Button>
	);
};

const SettingsCard = () => {
	const { users } = useSelector((state) => state.user);
	const { t } = useTranslation();
	const location = useLocation();

	const linkObjs = [
		{ id: 0, link: "color", children: t("settings.title.color") },
		{ id: 1, link: "form", children: t("settings.title.form") },
		{ id: 2, link: "config", children: t("settings.title.setting") },
		{ id: 3, link: "sense", children: t("settings.title.defect") },
		{ id: 4, link: "users", children: t("settings.title.users") },
	];

	return (
		<>
			<Wrapper>
				<Box
					sx={{ pt: "25px" }}
					display="flex"
					flexDirection={"row"}
					alignItems="center"
					gap="2rem"
				>
					{linkObjs.map((link) => (
						<Box key={link.id}>
							{!["moderator", "admin"].includes(users?.role) &&
							[3, 4].includes(link.id) ? null : (
								<LinkButton
									link={link.link}
									sx={{
										border: location.pathname
											.split("/")
											.includes(link.link)
											? "3px solid #7CC6B6"
											: "",
										color: location.pathname
											.split("/")
											.includes(link.link)
											? "#7CC6B6"
											: "black",
									}}
								>
									{link.children}
								</LinkButton>
							)}
						</Box>
					))}
					<Divider
						sx={{ pt: "46px", color: "black" }}
						variant="middle"
					/>
				</Box>
				<Outlet />
			</Wrapper>
		</>
	);
};

export default SettingsCard;
