import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import StatItemTable from "./StatItemTable";

const titleData = [
	{ title: "Форма", width: "120px" },
	{ title: "Цвет", width: "200px" },
	{ title: "Оператор", width: "200px" },
	{ title: "Тактов с продукцией", width: "120px" },
	{ title: "Выход продукции", width: "120px" },
	{ title: "Выход с дефектами", width: "120px" },
	{ title: "Доля дефектов", width: "120px" },
	{ title: "Средняя высота", width: "120px" },
];

const itemData = [
	{
		form: "3A6",
		color: "Антрацит гладкий",
		user: "Петренко Роман",
		ticks: "5658",
		density: "6012",
		density_defects: "123",
		defect_percent: "3",
		avg_height: "62.3",
	},
	{
		form: "3A6",
		color: "Антрацит гладкий",
		user: "Иванченко Василий",
		ticks: "5679",
		density: "6012",
		density_defects: "123",
		defect_percent: "3",
		avg_height: "62.3",
	},
	{
		form: "3A6",
		color: "Серый с черным гранит",
		user: "Петренко Роман",
		ticks: "5678",
		density: "6012",
		density_defects: "123",
		defect_percent: "3",
		avg_height: "62.3",
	},
	{
		form: "3A6",
		color: "Серый с черным гранит",
		user: "Иванченко Василий",
		ticks: "5677",
		density: "6012",
		density_defects: "123",
		defect_percent: "3",
		avg_height: "62.3",
	},
];

const Wrapper = styled.div`
	margin-top: 60px;
	padding: 12px;
	background-color: white;
	border-radius: 12px;
	width: fit-content;
	margin-left: 12px;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const Title = styled.div`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	width: ${(props) => props.width};
`;

const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StatTable = () => {
	const { stats } = useSelector((state) => state.stats);

	if (!stats) return <></>;

	const groupByKey = (list, key, { omitKey = false }) =>
		list.reduce(
			(hash, { [key]: value, ...rest }) => ({
				...hash,
				[value]: (hash[value] || []).concat(
					omitKey ? { ...rest } : { [key]: value, ...rest }
				),
			}),
			{}
		);

	const formFilter = stats.map((stat) => {
		return groupByKey(stat, "day", { omitKey: true });
	});

	console.log(formFilter);

	return (
		<Wrapper>
			<TitleWrapper>
				{titleData.map((data) => (
					<Title key={data.title} width={data.width}>
						{data.title}
					</Title>
				))}
			</TitleWrapper>
			<ItemWrapper>
				{itemData.map((data) => (
					<StatItemTable data={data} key={data.ticks} />
				))}
			</ItemWrapper>
		</Wrapper>
	);
};

export default StatTable;
