import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate, Navigate } from "react-router-dom";

import styled from "styled-components";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { authLogout } from "../store/authSlice";
import { switchLight } from "../store/rfidSlice";
import {
	closeUserSession,
	getCurrentUserSession,
	getUser,
} from "../store/userSlice";

import "../styles/Switch.css";
import { clearAllData } from "../store/palletSlice";
import { clearAllClickedData } from "../store/clickedSlice";

const MyHeader = styled.header`
	background-color: #4357a1;
	padding: 2px 16px;
	display: flex;
	align-items: center;
	border-radius: 0 0  8px 8px;
	position: sticky;
	z-index: 10;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const LinkWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 1rem;
`;

const UserWrap = styled.div`
	color: white;
	display: flex;
	gap: 1rem;
	flex-direction: row;
	align-items: center;
`;

const MyLink = styled(NavLink)`
	font-family: "Montserrat", sans-serif;
	color: white;
`;

const setActive = ({ isActive }) => ({ color: isActive ? "#7CC6B6" : "white" });

const Layout = () => {
	const { users, currentUserSession } = useSelector((state) => state.user);
	const { apiToken } = useSelector((state) => state.auth);
	const { switchState } = useSelector((state) => state.rfid);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [selectUser, setSelectUser] = useState();

	const isPhone = useMediaQuery("(max-width: 450px)");

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUser());
		dispatch(getCurrentUserSession());
		setSelectUser(currentUserSession?.username);
	}, [currentUserSession?.username, selectUser]);

	useEffect(() => {
		if (switchState) {
			setTimeout(() => dispatch(switchLight(false)), 1500);
		}
	}, [switchState]);

	const outHandler = (e) => {
		e.preventDefault();
		setSelectUser(null);
		dispatch(closeUserSession({ username: selectUser }));
		dispatch(authLogout());
		navigate("/login");
	};

	const screenHandler = (e) => {
		e.preventDefault();
		dispatch(clearAllData());
		dispatch(clearAllClickedData());
		navigate("/");
	};

	const profileHandler = (e) => {
		e.preventDefault();
		navigate("/profile");
	};

	return (
		<>
			{apiToken ? (
				<>
					<MyHeader>
						<LinkWrap>
							<MyLink
								onClick={screenHandler}
								to="/"
								style={setActive}
							>
								{t("layoutScreen")}
							</MyLink>
							<MyLink to="/newReport">
								{t("layoutProdReport")}
							</MyLink>
							{
								<MyLink to="/settings/color">
									{t("layoutSettings")}
								</MyLink>
							}
							<MyLink to="/rfid">{t("layoutDry")}</MyLink>
							<MyLink to="/notion">{t("layoutInstr")}</MyLink>
						</LinkWrap>
						<UserWrap>
							<Typography
								sx={{
									fontSize: isPhone ? "12px" : "",
									cursor: "pointer",
								}}
								onClick={profileHandler}
							>
								{users?.name}
							</Typography>
							<Button
								sx={{
									color: "white",
									fontSize: isPhone ? "12px" : "",
								}}
								onClick={(e) => outHandler(e)}
							>
								{t("layoutExit")}
							</Button>
						</UserWrap>
					</MyHeader>
					<Outlet />
				</>
			) : (
				<Navigate replace to={"/login"} />
			)}
		</>
	);
};

export default Layout;
